import { sendHttpRequest } from 'utils/httpRequestManager';
import { NavigateFunction } from 'react-router-dom';

export interface MenuItem {
	position: string;
	menu_item_name: string;
	page: string;
	action: string | null;
	icon: string | null;
	i_business: number | null;
	description: string | null;
	personal_menu: boolean;
	children?: MenuItem[];
	name?: string;
	role?: string;
	location?: string;
}

export async function getMenuItems(navigate: NavigateFunction) {
	const response = await sendHttpRequest('GET', '/menu', null, navigate);
	return parseMenuItems(response.data[0]);
}

function parseMenuItems(menuItems: MenuItem[]) {
	const parsedMenuItems: MenuItem = {
		position: '0',
		menu_item_name: 'root',
		page: '',
		action: '',
		icon: '',
		i_business: null,
		description: '',
		personal_menu: false,
		children: []
	};

	menuItems.sort((a: MenuItem, b: MenuItem) => {
		const as = a.position.split('.');
		const bs = b.position.split('.');
		if (as.length != bs.length) {
			return as.length - bs.length;
		}
		for (let i = 0; i < as.length; i++) {
			if (parseInt(as[i]) != parseInt(bs[i])) {
				return parseInt(as[i]) - parseInt(bs[i]);
			}
		}
		return 1;
	});

	menuItems.forEach((item: MenuItem) => {
		if (item.position === '000' && item.menu_item_name.includes('(')) {
			item.name = item.menu_item_name.substring(0, item.menu_item_name.indexOf('(') - 1).trim();
			item.role = item.menu_item_name
				.substring(item.menu_item_name.indexOf('-') + 1, item.menu_item_name.indexOf(')'))
				.trim();
			item.location = item.menu_item_name
				.substring(item.menu_item_name.indexOf('(') + 1, item.menu_item_name.indexOf('-') - 1)
				.trim();
			if (item.location.includes('Tupperware')) {
				item.location = item.location.replace('Tupperware', '').trim();
			}
		}

		const posParts = item.position.split('.');

		let currentMenu = parsedMenuItems;

		for (let i = 0; i < posParts.length; i++) {
			if (i == posParts.length - 1) {
				if (currentMenu.children === undefined) {
					currentMenu.children = [];
				}
				currentMenu.children.push(item);
			} else if (currentMenu.children !== undefined) {
				const newMenu = currentMenu.children.find(
					(child) => child.position.split('.')[i] === posParts[i]
				);
				if (newMenu) {
					currentMenu = newMenu;
				} else {
					break;
				}
			}
		}
	});

	return parsedMenuItems;
}
