import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { logOut } from 'utils/httpRequestManager';
import { useDispatch } from 'react-redux';

export default function Logout() {
	const dispatch = useDispatch();

	useEffect(() => {
		logOut(dispatch);
	}, [dispatch]);

	return <Navigate to="/login" />;
}
