import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Login from 'pages/Login';
import Home from 'pages/Home';
import ForgotPassword from 'pages/ForgotPassword';
import ResetPassword from 'pages/ResetPassword';
import ProtectedRoute from 'utils/ProtectedRoute';
import Logout from 'pages/Logout';
import DynamicPage from 'pages/DynamicPage';
import JDEExport from 'pages/JDEExport';
import ProductImport from 'pages/ProductImport';

export default function Router() {
	return (
		<Routes>
			<Route path="/login" element={<Login />} />
			<Route path="/logout" element={<Logout />} />
			<Route path="/forgot-password" element={<ForgotPassword />} />
			<Route path="/reset-password/:hash" element={<ResetPassword />} />
			<Route path="/jde-export" element={<JDEExport />} />
			<Route path="/product-import" element={<ProductImport />} />
			<Route path="/" element={<ProtectedRoute page={<Home />} />} />
			<Route path="*" element={<ProtectedRoute page={<DynamicPage />} />} />
		</Routes>
	);
}
