import React, { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import Input from 'components/Forms/FormComponents/Input';
import { useSendRequest } from 'sockets/default';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'state/store';
import { clearResponse } from 'state/socketSlice';
import ProgressModal from 'components/parts/ProgressModal';

export default function JDEExport() {
	const [formDisabled, setFormDisabled] = useState(false);
	const [showModal, setShowModal] = useState(false);

	const dispatch = useDispatch();

	const sendRequest = useSendRequest();
	const requestName = 'product_export_jde';

	const responseData = useSelector((state: RootState) => state.socket.responses).find(
		(r) => r.request === requestName
	);

	const status = responseData ? (responseData?.response as { status: string }).status : null;

	useEffect(() => {
		dispatch(clearResponse(requestName));
	}, [dispatch]);

	const closeModal = () => {
		setShowModal(false);
		setFormDisabled(false);
		dispatch(clearResponse(requestName));
	};

	type Inputs = {
		i_list: string;
	};

	const {
		register,
		handleSubmit,
		formState: { errors }
	} = useForm<Inputs>();
	const onSubmit: SubmitHandler<Inputs> = (data) => {
		setFormDisabled(true);
		setShowModal(true);
		sendRequest(requestName, { i_list: data.i_list });
	};

	return (
		<>
			<div className="row justify-content-center">
				<div className="col-5">
					<h1 className="mb-4 mt-3">JDE Export</h1>
					<form onSubmit={handleSubmit(onSubmit)} noValidate>
						<Input
							name="i_list"
							type="number"
							label="List ID"
							required={true}
							disabled={formDisabled}
							register={register}
							error={errors.i_list}
						/>
						<button type="submit" className="btn btn-primary w-100" disabled={formDisabled}>
							Submit
						</button>
					</form>
				</div>
				<ProgressModal status={status} showModal={showModal} closeModal={closeModal} />
			</div>
		</>
	);
}
