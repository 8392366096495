import React from 'react';
import { AppComponent, ComponentDefinition } from 'utils/components';
import { Link } from 'react-router-dom';

export default function Redirect(props: {
	component: AppComponent;
	definition: ComponentDefinition | undefined;
}) {
	// console.warn('unimplemented', props.component);

	const variables = JSON.parse(props.component.variables);

	return (
		<Link className={(props.definition?.class ?? '') + ' my-4'} to={variables.i_page}>
			{props.definition?.component_body}
		</Link>
	);
}
