import React, { useEffect } from 'react';
import Header from 'components/Header';
import Router from 'router/Router';
import { isUserLoggedIn, sendHttpRequest } from 'utils/httpRequestManager';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'state/store';
import { useNavigate } from 'react-router-dom';
import { setComponents } from 'state/componentSlice';
import { useSockets } from 'sockets/sockets';

export default function App() {
	useSockets();

	const navigate = useNavigate();
	const dispatch = useDispatch();

	const components = useSelector((state: RootState) => state.components.components);

	useEffect(() => {
		if (isUserLoggedIn() && components.length === 0) {
			sendHttpRequest('GET', '/components', null, navigate).then((response) => {
				dispatch(setComponents(response.data[0]));
			});
		}
	}, [components, dispatch, navigate]);

	return (
		<div className="App">
			<Header />
			<main className="container-fluid px-4">
				<Router />
			</main>
		</div>
	);
}
