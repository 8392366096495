import React from 'react';
import { AppComponent } from 'utils/components';
import { Link } from 'react-router-dom';

type BreadCrumbItemProps = {
	i_page?: string;
	caption: string;
};

export default function BreadCrumbItem(props: { component: AppComponent; active: boolean }) {
	const { i_page, caption } = JSON.parse(props.component.variables) as BreadCrumbItemProps;

	return (
		<li
			className={'breadcrumb-item' + (props.active ? ' active' : '')}
			aria-current={props.active ? 'page' : undefined}>
			{i_page && <Link to={i_page}>{caption}</Link>}
			{!i_page && caption}
		</li>
	);
}
