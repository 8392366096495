import React, { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { isUserLoggedIn } from 'utils/httpRequestManager';

export default function ProtectedRoute(props: { page?: ReactNode }) {
	if (!isUserLoggedIn()) {
		return <Navigate to="/login" />;
	}

	return <>{props.page}</>;
}
