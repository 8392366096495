import React, { useRef, useState, useEffect } from 'react';
import { StockItem } from 'components/Forms/ProductsForm';
import { StockItemOption } from 'components/Forms/Promotion/PromotionProduct';
import { useSelector } from 'react-redux';
import { RootState } from 'state/store';
import PromotionCell from 'components/Forms/Promotion/PromotionCell';

export default function PromotionStockItem(props: {
	template: string;
	productId: number;
	productIndex: number;
	i: number;
	item: StockItem;
	setValue: (
		item_id: number | null,
		tab_name: string | null,
		input_name: string,
		value: string,
		country_index?: number
	) => void;
	tpagMismatch: boolean;
}) {
	const [stockItemsDropdown, setStockItemsDropdown] = useState([] as StockItemOption[]);
	const stockItemOptions = useSelector((state: RootState) => state.stockItem.stockItems) ?? [];
	const [unknownStockItems, setUnknownStockItems] = useState([] as number[]);
	const [focusedStockItemOption, setFocusedStockItemOption] = useState(-1);
	const [isFocused, setIsFocused] = useState(false);

	function pickStockItems(q: string) {
		const si = stockItemOptions
			.filter((s) => s.c_item.startsWith(q) || s.n_item.toLowerCase().includes(q.toLowerCase()))
			.slice(0, 10);
		setStockItemsDropdown(si);
	}

	function checkInputEqualsStockItem(value: string) {
		const foundItem = stockItemOptions.find((item) => item.c_item === value);
		setStockItem(foundItem);
	}

	const stockItemOptionsRefs = useRef([] as HTMLLIElement[]);
	const stockItemCodeInputRef = useRef(null as HTMLInputElement | null);

	function onKeyDown(e: React.KeyboardEvent, stockItemTarget = null as StockItemOption | null) {
		if (stockItemTarget) {
			// the target is one of the list items
			e.preventDefault();
			if (e.key === 'Enter') {
				setStockItem(stockItemTarget);
			}
		}

		let newFocusedItemOption = focusedStockItemOption;
		if (e.key === 'ArrowDown') {
			newFocusedItemOption = Math.min(stockItemsDropdown.length - 1, focusedStockItemOption + 1);
			stockItemOptionsRefs.current[newFocusedItemOption]?.focus();

			setFocusedStockItemOption(newFocusedItemOption);
		} else if (e.key === 'ArrowUp') {
			newFocusedItemOption = Math.max(-1, focusedStockItemOption - 1);
			if (newFocusedItemOption !== -1) {
				stockItemOptionsRefs.current[newFocusedItemOption].focus();
			} else {
				stockItemCodeInputRef.current?.focus();
			}

			setFocusedStockItemOption(newFocusedItemOption);
		} else {
			setFocusedStockItemOption(-1);
		}

		if (['Tab', 'Escape'].includes(e.key)) {
			setIsFocused(false);
			stockItemCodeInputRef.current?.focus();
		}
	}

	function setStockItem(stockItem: StockItemOption | undefined) {
		props.setValue(props.i, null, 'c_item', stockItem ? stockItem.c_item : '');
		props.setValue(props.i, null, 'n_item', stockItem ? stockItem.n_item : '');
		props.setValue(props.i, null, 'srp', stockItem ? stockItem.srp : '');

		if (stockItem === undefined && !unknownStockItems.includes(props.i)) {
			setUnknownStockItems([...unknownStockItems, props.i]);
		} else if (stockItem !== undefined && unknownStockItems.includes(props.i)) {
			setUnknownStockItems(unknownStockItems.filter((item) => item !== props.i));
		}

		if (stockItem !== undefined) {
			setIsFocused(false);
		}
	}

	function isStockItemDropdownClicked(target: HTMLElement | null) {
		while (target) {
			if (target.classList.contains('stock-item-dropdown')) {
				return true;
			}
			target = target.parentElement;
		}
		return false;
	}

	function useHandleDocumentClick() {
		useEffect(() => {
			function handleClickOutside(event: MouseEvent) {
				const target = event.target as HTMLElement;
				if (!isStockItemDropdownClicked(target)) {
					setIsFocused(false);
				}
			}

			document.addEventListener('click', handleClickOutside);
			return () => {
				document.removeEventListener('click', handleClickOutside);
			};
		}, []);
	}

	useHandleDocumentClick();

	return (
		<div className="mt-2 row">
			<div className="col-1 px-1">
				<PromotionCell
					template={props.template}
					productIndex={props.productIndex}
					productId={props.productId}
					name={'quantity'}
					type={'number'}
					min={1}
					required={true}
					defaultValue={props.item.quantity?.toString()}
					setValue={props.setValue}
					itemId={props.i}
				/>
			</div>
			<div className="col-7 px-1 position-relative">
				<div className="input-group stock-item-group">
					<input
						id={props.template + '.' + props.productIndex + '.' + props.i + '.c_item'}
						className="form-control"
						ref={stockItemCodeInputRef}
						name={props.template + '.' + props.productIndex + '.' + props.i + '.c_item'}
						type="text"
						required={true}
						value={props.item.c_item}
						onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
							checkInputEqualsStockItem(e.target.value);
							setStockItemsDropdown([]);
							pickStockItems(e.target.value);
							props.setValue(props.i, null, 'c_item', e.target.value);
							setIsFocused(true);
						}}
						onKeyDown={(e) => onKeyDown(e)}
					/>

					<PromotionCell
						template={props.template}
						productIndex={props.productIndex}
						productId={props.productId}
						name={'n_item'}
						type={'text'}
						required={true}
						setValue={props.setValue}
						itemId={props.i}
						defaultValue={props.item.n_item}
						disabled={!unknownStockItems.includes(props.i)}
					/>
				</div>
				{isFocused && stockItemsDropdown.length > 0 && (
					<ul className="stock-item-dropdown">
						{stockItemsDropdown.map((stockItem, j) => (
							<li
								tabIndex={0}
								ref={(el) => (stockItemOptionsRefs.current[j] = el as HTMLLIElement)}
								className="stock-item-dropdown-content font-small py-1 px-2"
								onClick={() => setStockItem(stockItem)}
								onKeyDown={(e) => onKeyDown(e, stockItem)}
								key={j}>
								<b>{stockItem.c_item}</b> - {stockItem.n_item}
							</li>
						))}
					</ul>
				)}
			</div>
			<div className="col-2 px-1">
				<PromotionCell
					template={props.template}
					productIndex={props.productIndex}
					productId={props.productId}
					name="srp"
					type="number"
					customClass="text-end"
					required={true}
					disabled={!unknownStockItems.includes(props.i)}
					defaultValue={props.item.srp?.toString()}
					setValue={props.setValue}
					itemId={props.i}
					onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
						e.preventDefault();
					}}
					min={0}
					step={0.01}
				/>
			</div>
			<div className="col-2 px-1">
				<PromotionCell
					template={props.template}
					productIndex={props.productIndex}
					productId={props.productId}
					name="base_price"
					type="number"
					customClass={'text-end' + (props.tpagMismatch ? ' bg-red-tint is-invalid' : '')}
					required={true}
					disabled={!unknownStockItems.includes(props.i)}
					defaultValue={props.item.base_price?.toString() ?? ''}
					setValue={props.setValue}
					itemId={props.i}
					min={0}
					step={0.01}
				/>
			</div>
		</div>
	);
}
