import React from 'react';
import { AppComponent, ComponentDefinition } from 'utils/components';
import BreadCrumb from 'components/parts/BreadCrumb';
import Button from 'components/parts/Button';
import Col from 'components/parts/Col';
import Dropdown from 'components/parts/Dropdown';
import Form from 'components/parts/Form';
import FormChangePassword from 'components/parts/FormChangePassword';
import Grid from 'components/parts/Grid';
import InfoPanel from 'components/parts/InfoPanel';
import ListItem from 'components/parts/ListItem';
import Redirect from 'components/parts/Redirect';
import Row from 'components/parts/Row';
import Tab from 'components/parts/Tab';
import Table from 'components/parts/Table';
import Text from 'components/parts/Text';
import TextInput from 'components/parts/TextInput';
import ProductsForm from 'components/Forms/ProductsForm';

export default function DynamicComponent(props: {
	component: AppComponent;
	definition: ComponentDefinition | undefined;
	has_definitions: boolean;
}) {
	switch (props.definition?.i_component_type) {
		case 'a_create_products':
			return <ProductsForm component={props.component} />;
		case 'breadcrumb_start':
			return <BreadCrumb component={props.component} />;
		case 'button':
			return <Button component={props.component} />;
		case 'col_start':
			return <Col component={props.component} definition={props.definition} />;
		case 'dropdown':
			return <Dropdown component={props.component} />;
		case 'form':
			return <Form component={props.component} />;
		case 'form_change_password':
			return <FormChangePassword component={props.component} />;
		case 'grid':
			return <Grid component={props.component} definition={props.definition} />;
		case 'info_panel':
			return <InfoPanel component={props.component} />;
		case 'link':
			return <></>;
		case 'm_list':
			return <ListItem component={props.component} />;
		case 'redirect':
			return <Redirect component={props.component} definition={props.definition} />;
		case 'row_start':
			return <Row component={props.component} />;
		case 'tab':
			return <Tab component={props.component} definition={props.definition} />;
		case 'table':
			return <Table component={props.component} />;
		case 'text':
			return <Text component={props.component} />;
		case 'text_input':
			return <TextInput component={props.component} />;
	}

	if (props.has_definitions) {
		console.warn(
			'Unknown component:',
			props.component.i_component,
			'type:',
			props.definition?.i_component_type
		);
	}

	return <></>;
}
