import React from 'react';
import { AppComponent } from 'utils/components';
import BreadCrumbItem from 'components/parts/BreadCrumbItem';

export default function BreadCrumb(props: { component: AppComponent }) {
	if (!props.component.children) return <></>;

	return (
		<nav className="breadcrumb-nav" aria-label="breadcrumb">
			<ol className="breadcrumb">
				{props.component.children.map((c, i) => (
					<BreadCrumbItem
						key={i}
						component={c}
						active={
							props.component.children !== undefined && i === props.component.children.length - 1
						}
					/>
				))}
			</ol>
		</nav>
	);
}
