import React from 'react';

export default function Alert(props: {
	message: string;
	type: 'success' | 'warning' | 'danger' | 'info';
}) {
	return (
		<div className={`alert alert-sm alert-${props.type}`}>
			{props.type === 'success' && <i className="me-1 bi bi-check-circle"></i>}
			{props.type === 'warning' && <i className="me-1 bi bi-exclamation-circle"></i>}
			{props.type === 'danger' && <i className="me-1 bi bi-exclamation-triangle"></i>}
			{props.type === 'info' && <i className="me-1 bi bi-info"></i>}
			{props.message}
		</div>
	);
}
