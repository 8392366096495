import React from 'react';
import { Product, Tab } from 'components/Forms/ProductsForm';
import PromotionProduct from 'components/Forms/Promotion/PromotionProduct';
import PromotionTemplateSummary from 'components/Forms/Promotion/PromotionTemplateSummary';

export default function PromotionTemplate(props: {
	template: string;
	tabs: Tab[];
	countriesTabs: Map<string, { i_country: string; tabs: Tab[] }>;
	products: Product[];
	index: number;
	viewLevel: 'stock_items' | 'products' | 'summary';
	addProduct: (template_index: number) => void;
	i_business: number;
	countries: string[];
	omitColumns: string[];
	setOmitColumns: (omitColumns: string[]) => void;
}) {
	function sumPrice(type: 'srp' | 'base_price', product: Product) {
		return product.items.reduce(
			(a, b) => a + parseFloat((b[type] ?? 0).toString()) * parseInt(b.quantity.toString()),
			0
		);
	}

	function templateSumPrice(type: 'srp' | 'base_price') {
		let sum = 0.0;
		for (const p of props.products) {
			sum += sumPrice(type, p);
		}

		return sum;
	}

	function templateSumRetailPrice() {
		return props.products.reduce((a, b) => a + parseFloat((b.retail_price ?? 0).toString()), 0);
	}

	function getValue(tab_key: string | null, col_key: string, productIndex: number, c?: string) {
		const product = props.products[productIndex];
		if (c) {
			const country = product.countries.find((pc) => pc.i_country === c);
			if (!country) {
				return '';
			}
			if (col_key == 'product_name') {
				return country?.product_name ?? '';
			}
			const tab = (country[tab_key as keyof typeof country] as object[])[0];
			return tab[col_key as keyof typeof tab] ?? '';
		}
		const values = product.countries.map((pc) => {
			const tab = (pc[tab_key as keyof typeof pc] as object[])[0];
			return tab[col_key as keyof typeof tab] ?? '';
		});
		if (values.every((v) => v === values[0])) {
			return values[0] ?? '';
		}
		return '';
	}

	return (
		<section className={'template-wrapper ' + (props.viewLevel === 'summary' ? 'pt-3' : 'pb-3')}>
			{props.viewLevel !== 'summary' && (
				<div className="template-name-wrapper">
					<div className="template-name bg-primary color-primary-shade">
						<h2>{props.template}</h2>
					</div>
				</div>
			)}
			{props.viewLevel == 'summary' && <div className="template-name bg-white"></div>}
			<div className="ms-3">
				{props.viewLevel == 'summary' && (
					<PromotionTemplateSummary
						data={props.products}
						template={props.template}
						srpTemplateTotal={templateSumPrice('srp')}
						basePriceTemplateTotal={templateSumPrice('base_price')}
						retailPriceTemplateTotal={templateSumRetailPrice()}
						showTemplate={props.viewLevel === 'summary'}
						getValue={getValue}
						tabs={props.tabs}
						countries={props.countries}
						omitColumns={props.omitColumns}
						setOmitColumns={props.setOmitColumns}
					/>
				)}
				{['products', 'stock_items'].includes(props.viewLevel) &&
					props.products.map((product, index) => (
						<PromotionProduct
							key={index}
							index={index}
							tabs={props.tabs}
							countriesTabs={props.countriesTabs}
							product={product}
							showStockItems={props.viewLevel === 'stock_items'}
							retail_price={product.retail_price?.toString()}
							template={props.template}
							i_business={props.i_business}
							sumPrice={sumPrice}
							getValue={getValue}
							countries={props.countries}
							omitColumns={props.omitColumns}
						/>
					))}
				{['products', 'stock_items'].includes(props.viewLevel) && (
					<div className="add-product mt-3">
						<button onClick={() => props.addProduct(props.index)} className="btn btn-primary">
							<i className="bi bi-plus-lg me-1"></i>
							Add Product
						</button>
					</div>
				)}
			</div>
		</section>
	);
}
