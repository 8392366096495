import React, { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import PasswordInput from 'components/Forms/FormComponents/PasswordInput';
import { useNavigate, useParams } from 'react-router-dom';
import Loading from 'components/parts/Loading';
import { sendHttpRequest } from 'utils/httpRequestManager';
import Input from 'components/Forms/FormComponents/Input';
import Alert from 'components/parts/Alert';
import { MessageResponse } from 'utils/auth';
import { AxiosError } from 'axios';

export default function ResetPassword() {
	const [email, setEmail] = useState('');
	const [error, setError] = useState({ type: '', message: '' });
	const [confirmation, setConfirmation] = useState('' as string);
	const [loading, setLoading] = useState(false);

	const navigate = useNavigate();

	type Inputs = {
		password: string;
		passwordRepeat: string;
	};

	const { hash } = useParams();

	useEffect(() => {
		setLoading(true);
		sendHttpRequest('POST', '/reset-password-check', { hash }, navigate)
			.then((response) => {
				const r = response.data as { email: string };
				setEmail(r.email);
			})
			.catch((error) => {
				setError({ type: 'error', message: error.response?.data.message });
			})
			.finally(() => setLoading(false));
	}, [hash, navigate]);

	const {
		register,
		handleSubmit,
		formState: { errors }
	} = useForm<Inputs>();

	const removeError = () => {
		setError({ type: '', message: '' });
	};

	const onSubmit: SubmitHandler<Inputs> = (data) => {
		if (data.password !== data.passwordRepeat) {
			setError({ type: 'custom', message: 'Passwords do not match' });
			return;
		}
		removeError();
		const requestData = {
			hash: hash,
			password: data.password
		};
		setLoading(true);
		sendHttpRequest('POST', '/reset-password', requestData, navigate)
			.then((response) => {
				const r = response.data as MessageResponse;
				setConfirmation(r.message);
			})
			.catch((e) => {
				if (e instanceof AxiosError) {
					setError({ type: 'error', message: e.response?.data.message });
				}
			})
			.finally(() => setLoading(false));
	};

	return (
		<>
			<div className="row justify-content-center">
				<div className="col-12 col-sm-8 col-lg-4 py-5">
					<h1 className="text-center mb-5">Password reset</h1>
					{error.type !== 'custom' && error.message !== '' && !confirmation && (
						<Alert type="danger" message={error.message} />
					)}
					{loading && <Loading />}
					{email !== '' && !loading && confirmation !== '' && (
						<>
							<Alert type="success" message={confirmation} />
							<button
								type="button"
								className="btn btn-primary w-100 mt-1"
								onClick={() => navigate('/login')}>
								Go to Sign in
							</button>
						</>
					)}
					{email !== '' && !loading && confirmation === '' && (
						<form onSubmit={handleSubmit(onSubmit)} noValidate>
							<Input<Inputs>
								name="email"
								label="Email address"
								type="text"
								required={false}
								disabled={true}
								register={register}
								defaultValue={email}
							/>

							<PasswordInput<Inputs>
								name="password"
								label="New password"
								required={true}
								register={register}
								pattern={/(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/}
								error={errors.password}
								autoComplete={'new-password'}
								customError={error.type === 'custom' ? error.message : undefined}
								onChange={removeError}
							/>

							<PasswordInput<Inputs>
								name="passwordRepeat"
								label="Repeat new password"
								required={true}
								register={register}
								autoComplete={'new-password'}
								error={errors.passwordRepeat}
								customError={error.type === 'custom' ? error.message : undefined}
								onChange={removeError}
							/>

							<button type="submit" className="btn btn-primary w-100 mt-1">
								Submit
							</button>
						</form>
					)}
				</div>
			</div>
		</>
	);
}
