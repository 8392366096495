import React, { Fragment, useMemo, useState } from 'react';
import Input from 'components/Forms/FormComponents/Input';
import { Product, Tab } from 'components/Forms/ProductsForm';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import PromotionStockItem from 'components/Forms/Promotion/PromotionStockItem';
import PromotionCell from 'components/Forms/Promotion/PromotionCell';
import { sendHttpRequest } from 'utils/httpRequestManager';
import { useNavigate } from 'react-router-dom';

export type StockItemOption = {
	c_item: string;
	n_item: string;
	srp: string;
	base_price: string | null;
	tpag_price: string | null;
};

export default function PromotionProduct(props: {
	template: string;
	tabs: Tab[];
	countriesTabs: Map<string, { i_country: string; tabs: Tab[] }>;
	product: Product;
	retail_price: string;
	index: number;
	showStockItems: boolean;
	i_business: number;
	sumPrice: (type: 'srp' | 'base_price', product: Product, c?: string) => number;
	getValue: (tab_key: string | null, col_key: string, productIndex: number, c?: string) => string;
	countries: string[];
	omitColumns: string[];
}) {
	const navigate = useNavigate();

	const [product, setProduct] = useState(props.product);

	const tpagMismatch = useMemo(() => {
		return product.items.some(
			(item) => item.base_price == null || item.base_price != item.tpag_price
		);
	}, [product.items]);

	const [saveTimer, setSaveTimer] = useState(null as NodeJS.Timeout | null);

	function setValue(
		item_id: number | null,
		tab_name: string | null,
		input_name: string,
		value: string,
		country_index?: number
	) {
		const d = product as any;
		if (country_index != undefined) {
			if (item_id == null && tab_name == null) {
				d.countries[country_index][input_name] = value;
			} else if (tab_name != null) {
				d.countries[country_index][tab_name][0][input_name] = value;
			}
		} else {
			if (item_id == null && tab_name == null) {
				d[input_name] = value;
			} else if (tab_name != null) {
				d.countries = d.countries.map((c: any) => {
					c[tab_name][0][input_name] = value;
					return c;
				});
			} else if (item_id != null) {
				d.items[item_id][input_name] = value;
			}
		}

		if (input_name == 'quantity' && value == '0') {
			d.items = d.items.filter((_item: any, i: number) => i !== item_id);
		}

		setProduct({ ...product });

		if (saveTimer !== null) {
			clearTimeout(saveTimer);
		}

		const urlParams = new URLSearchParams(window.location.search);

		setSaveTimer(
			setTimeout(() => {
				sendHttpRequest(
					'POST',
					'/promotion/' + (urlParams.get('i_list') ?? '1') + '/save',
					{ json: d, i_business: props.i_business },
					navigate
				).then(() => {
					setSaveTimer(null);
				});
			}, 1000)
		);
	}

	function addItem() {
		product.items.push({
			c_item: '',
			i_item: '',
			n_item: '',
			quantity: 1,
			srp: '0.00',
			tpag_price: '0.00',
			base_price: '0.00'
		});
		setProduct({ ...product });
	}

	return (
		<div className="row mx-0 d-flex flex-nowrap">
			<div className="promotion-sticky-left pe-2 py-2">
				<div className="products-wrapper">
					<div className="row mx-0">
						<div className="col-5 px-1">
							<Input
								name={props.template + '.' + props.index + '.product_code'}
								label={'Product code'}
								type="text"
								disabled={true}
								required={true}
								defaultValue={product.product_price_code}
							/>
						</div>

						<div className="col-3 px-1">
							<Input
								name={props.template + '.' + props.index + '.retail_price'}
								label={'Retail price'}
								type="number"
								customClass="text-end"
								min={0}
								step={0.01}
								required={true}
								defaultValue={product.retail_price.toString()}
								onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
									setValue(null, null, 'retail_price', e.target.value)
								}
							/>
						</div>

						<div className="col-2 px-1">
							<Input
								name={props.template + '.' + props.index + '.srp_total'}
								label={'SRP'}
								type="number"
								customClass="text-end"
								min={0}
								disabled={true}
								step={0.01}
								defaultValue={props.sumPrice('srp', product).toFixed(2)}
								required={true}
							/>
						</div>

						<div className="col-2 px-1">
							<Input
								name={props.template + '.' + props.index + '.base_price_total'}
								label={'Base price'}
								type="number"
								customClass="text-end"
								min={0}
								disabled={true}
								step={0.01}
								defaultValue={props.sumPrice('base_price', product).toFixed(2)}
								required={true}
							/>
						</div>

						{props.countries.map((c, i) => (
							<div className="col-12 mt-2" key={i}>
								<div className="row align-items-center">
									<div className="col-1 px-2 country-label">{c}</div>
									<div className="col-11 px-1">
										<PromotionCell
											template={props.template}
											productIndex={props.index}
											productId={product.id}
											country={c}
											countryIndex={i}
											name={'product_name'}
											type={'text'}
											required={true}
											getValue={props.getValue}
											setValue={setValue}
										/>
									</div>
								</div>
							</div>
						))}

						{props.showStockItems && (
							<div className="col-12 mt-2 mx-0">
								<div className="row stock-item-header-labels">
									<div className="col-1 ps-3 pe-0">Qty</div>
									<div className="col-7 px-3 d-flex justify-content-between">
										Stock item
										<button type="button" className="link" onClick={() => addItem()}>
											+&nbsp;Add
										</button>
									</div>
									<div className="col-4 ps-3 pe-2 pe-0 d-flex justify-content-end">
										{tpagMismatch && (
											<OverlayTrigger
												overlay={
													<Tooltip id="tooltip">
														{product.items.some((item) => item.tpag_price == null) ? (
															'TPAG price request missing.'
														) : (
															<>
																TPAG price mismatch, TPAG prices are:
																{product.items.map((item) =>
																	item.tpag_price ? ' ' + item.tpag_price : ' N/A'
																)}
															</>
														)}
													</Tooltip>
												}>
												<i
													data-bs-toggle="tooltip"
													data-bs-title="Default tooltip"
													className="bi bi-exclamation-triangle-fill color-red position-absolute"
													style={{ fontSize: '1.2rem' }}
												/>
											</OverlayTrigger>
										)}
									</div>
								</div>

								{product.items.map((item, i) => (
									<PromotionStockItem
										key={i}
										template={props.template}
										productId={product.id}
										productIndex={props.index}
										i={i}
										item={item}
										setValue={setValue}
										tpagMismatch={tpagMismatch}
									/>
								))}
							</div>
						)}
					</div>
				</div>
			</div>
			<div className="col-7 products-detail-wrapper ps-2 py-2">
				<div className="row tab-wrapper mt-0">
					<div className="d-flex p-0 m-0">
						{props.tabs.map((t, i) => (
							<Fragment key={i}>
								{t.columns.map((col, col_index) => (
									<Fragment key={col_index}>
										{!props.omitColumns.includes(col.key) && (
											<div
												className={'col px-1' + (col.type == 'datetime-local' ? ' datetime' : '')}>
												<Input
													label={col.name}
													name={props.template + '.' + props.index + '.' + i + '.' + col.key}
													type={col.type}
													customClass={col.type == 'number' ? 'text-end' : ''}
													step={col.type == 'number' ? 0.01 : undefined}
													defaultValue={props.getValue(t.key, col.key, props.index)}
													required={false}
													onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
														setValue(null, t.key, col.key, e.target.value)
													}
												/>
											</div>
										)}
									</Fragment>
								))}
							</Fragment>
						))}
					</div>
					{props.countries.map((c, c_index) => (
						<div key={c_index} className="d-flex pt-2 px-0">
							{props.countriesTabs.get(c)?.tabs.map((t, i) => (
								<Fragment key={i}>
									{t.columns.map((col, col_index) => (
										<Fragment key={col_index}>
											{!props.omitColumns.includes(col.key) && (
												<div
													className={
														'col px-1' + (col.type == 'datetime-local' ? ' datetime' : '')
													}>
													<PromotionCell
														template={props.template}
														productIndex={props.index}
														productId={product.id}
														country={c}
														countryIndex={c_index}
														name={col.key}
														type={col.type}
														step={col.type == 'number' ? 0.01 : undefined}
														required={false}
														defaultValue={props.getValue(t.key, col.key, props.index, c)}
														setValue={setValue}
														customClass={col.type == 'number' ? 'text-end' : ''}
														tabName={t.key}
													/>
												</div>
											)}
										</Fragment>
									))}
								</Fragment>
							))}
						</div>
					))}
				</div>
			</div>
		</div>
	);
}
