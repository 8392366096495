import React, { Fragment, useEffect, useState } from 'react';
import { sendHttpRequest } from 'utils/httpRequestManager';
import { useNavigate } from 'react-router-dom';
import { humanizeString } from 'utils/string';
import { AppComponent } from 'utils/components';
import { useDispatch } from 'react-redux';
import { setStockItems } from 'state/stockItemSlice';
import PromotionTemplate from 'components/Forms/Promotion/PromotionTemplate';
import { Dropdown } from 'react-bootstrap';
import Loading from 'components/parts/Loading';

export type Product = {
	i_product: number | null;
	i_template: string;
	i_usg: string;
	GP_group: string;
	items: StockItem[];
	product_price_code: string;
	retail_price: number | string;
	valid_from: string;
	valid_to: string;
	id: number;
	countries: ProductCountry[];
};

export type ProductCountry = {
	i_country: string;
	product_name: string;
	tab_retail: object[];
};

export type StockItem = {
	c_item: string;
	i_item: string;
	n_item: string;
	quantity: number | string;
	srp: number | string;
	tpag_price: number | string | null;
	base_price: number | string | null;
};

export type PromotionColumn = {
	name: string;
	key: string;
	decimal?: number;
	currency?: string;
	type: 'text' | 'number' | 'datetime-local';
};

export type Tab = {
	name: string;
	key: string;
	columns: PromotionColumn[];
};

export type PromotionStructure = {
	i_template: string;
	i_usg: string;
	GP_group: string;
	general_inputs: object[];
	product_inputs: object[];
	item_inputs: object[];
	tabs: Tab[];
	countries: Map<string, { i_country: string; tabs: Tab[] }>;
};

export type PromotionViewLayout = {
	i_prop: number;
	name: string;
	omit_columns: string[];
};

export default function ProductsForm(props: { component: AppComponent }) {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const [data, setData] = useState([] as Product[]);
	const [structures, setStructures] = useState([] as PromotionStructure[]);
	const [loading, setLoading] = useState(false);

	const [layouts, setLayouts] = useState([] as PromotionViewLayout[]);
	const [currentLayout, setCurrentLayout] = useState(null as PromotionViewLayout | null);

	const [templates, setTemplates] = useState([] as string[]);
	const [usages, setUsages] = useState([] as string[]);
	const [gpCategories, setGpCategories] = useState([] as string[]);
	const [omitColumns, setOmitColumns] = useState([] as string[]);

	const allCountries = ['AT', 'DE'];

	const [countries, setCountries] = useState(allCountries as string[]);

	const i_business = JSON.parse(props.component.variables ?? '{}').i_business;

	function toggleColumnVisibility(col_key: string) {
		if (omitColumns.includes(col_key)) {
			changeOmittedColumns(omitColumns.filter((c) => c !== col_key));
		} else {
			changeOmittedColumns([...omitColumns, col_key]);
		}
	}

	function hideAllColumns() {
		changeOmittedColumns(
			omitColumns.concat(
				structures[0]?.tabs.flatMap((t: { columns: { key: string }[] }) =>
					t.columns.map((c) => c.key)
				)
			)
		);
	}

	function showAllColumns() {
		changeOmittedColumns([]);
	}

	useEffect(() => {
		sendHttpRequest('GET', '/promotion-layout', { i_business: i_business }, navigate).then(
			(response) => {
				setLayouts(
					response.data[0].map(
						(row: { val: string; i_prop: number }) =>
							({ ...JSON.parse(row.val), i_prop: row.i_prop }) as PromotionViewLayout
					)
				);
			}
		);
	}, [navigate, i_business]);

	function saveLayout() {
		const newLayoutName = prompt('Enter layout name');
		if (!newLayoutName) {
			return;
		}

		const layout = {
			name: newLayoutName,
			omit_columns: omitColumns
		};

		layouts
			.filter((l) => l.name === newLayoutName)
			.forEach((l) =>
				sendHttpRequest(
					'DELETE',
					'/promotion-layout',
					{ i_prop: l.i_prop, i_business: i_business },
					navigate
				)
			);

		sendHttpRequest(
			'POST',
			'/promotion-layout',
			{ i_business: i_business, layout: layout },
			navigate
		).then((response) => {
			const data: { i_prop: number; val: string } = response.data[0][0];
			const newLayout: PromotionViewLayout = {
				...JSON.parse(data.val),
				i_prop: data.i_prop
			};

			setLayouts([...layouts.filter((l) => l.name !== newLayoutName), newLayout]);

			setCurrentLayout(newLayout);
		});
	}

	function loadLayout(layout: PromotionViewLayout) {
		setOmitColumns(layout.omit_columns);
		setCurrentLayout(layout);
	}
	function deleteLayout(layout: PromotionViewLayout) {
		const confirmation = confirm(
			'Are you sure you want to delete the layout "' + layout.name + '"?'
		);
		if (!confirmation) {
			return;
		}
		sendHttpRequest(
			'DELETE',
			'/promotion-layout',
			{ i_prop: layout.i_prop, i_business: i_business },
			navigate
		).then(() => {
			if (currentLayout == layout) {
				setCurrentLayout(null);
				setOmitColumns([]);
			}
			setLayouts(layouts.filter((l) => l.i_prop !== layout.i_prop));
		});
	}

	function changeOmittedColumns(c: string[]) {
		setOmitColumns(c);
		setCurrentLayout(null);
	}

	function addProduct(template_index: number) {
		// const data = [...data];
		const template = templates[template_index];
		data.push({
			i_product: null,
			i_template: template,
			i_usg: '',
			GP_group: '',
			items: [],
			product_price_code: '',
			retail_price: '',
			id: data.length,
			countries: [],
			valid_from: '',
			valid_to: ''
		});
		setData(data);
	}

	function convertToColumnDefinitions(data: object): PromotionColumn[] {
		return Object.entries(data).map(([colKey, colValue]) => {
			colValue = colValue[0];
			let type: 'text' | 'number' | 'datetime-local' = 'number';
			switch (colValue.type) {
				case 'datetime':
					type = 'datetime-local';
					break;
				case 'character':
					type = 'text';
					break;
			}

			return {
				name: humanizeString(colKey),
				key: colKey,
				decimal: colValue.decimal,
				currency: colValue.currency,
				type: type
			};
		});
	}

	useEffect(() => {
		sendHttpRequest('POST', '/promotion/structure', { i_business: i_business }, navigate).then(
			(response) => {
				const structures = response.data[0].map((row: { json: string; i_template: string }) => {
					const data = JSON.parse(row.json)[0];
					const countries: Map<string, { i_country: string; tabs: Tab[] }> = new Map();
					data.countries
						.map((c: { i_country: string; tab_retail: object }) => {
							return {
								i_country: c.i_country,
								tabs: Object.entries(c)
									.filter(([key]) => key !== 'i_country')
									.map(([key, value]) => {
										return {
											name: humanizeString(key.substring(4)),
											key: key,
											columns: convertToColumnDefinitions((value as object[])[0])
										} as Tab;
									})
							};
						})
						.forEach((c: { i_country: string; tabs: Tab[] }) => {
							countries.set(c.i_country, c);
						});
					return {
						i_template: row.i_template,
						general_inputs: convertToColumnDefinitions(data.general[0]),
						product_inputs: convertToColumnDefinitions(data.product[0]),
						item_inputs: convertToColumnDefinitions(data.items[0]),
						tabs: Object.entries(data.countries[0])
							.filter(([key]) => key !== 'i_country')
							.map(([key, value]) => {
								return {
									name: humanizeString(key.substring(4)),
									key: key,
									columns: convertToColumnDefinitions((value as object[])[0])
								} as Tab;
							}),
						countries: countries
					} as PromotionStructure;
				});
				setStructures(structures);
			}
		);
	}, [navigate, i_business]);

	const [viewLevel, setViewLevel] = useState(
		'stock_items' as 'stock_items' | 'products' | 'summary'
	);

	useEffect(() => {
		if (window.location.search.length > 0) {
			setLoading(true);
			const urlParams = new URLSearchParams(window.location.search);

			sendHttpRequest('GET', '/promotion/' + (urlParams.get('i_list') ?? '1'), null, navigate).then(
				(response) => {
					setData(
						response.data[0]
							.map((row: { prod_json: string }) => JSON.parse(row.prod_json ?? '{}'))
							.map((row: Product) => {
								if (row.retail_price !== undefined && row.retail_price !== null) {
									row.retail_price = (row.retail_price as number).toFixed(2);
								} else {
									row.retail_price = '';
								}
								if (row.items === undefined || row.items === null) {
									row.items = [];
								}
								for (const item of row.items) {
									if (item.srp !== undefined) {
										item.srp = ((item.srp ?? 0) as number).toFixed(2);
									}
								}
								return row;
							})
							.map((row: object, i: number) => {
								return {
									...row,
									id: i
								};
							})
					);
					setTemplates(
						Array.from(
							new Set(response.data[0].map((row: { i_template: string }) => row.i_template))
						)
					);
					setUsages(
						Array.from(new Set(response.data[0].map((row: { i_usg: string }) => row.i_usg)))
					);
					setGpCategories(
						Array.from(new Set(response.data[0].map((row: { GP_group: string }) => row.GP_group)))
					);
					setLoading(false);
				}
			);
		} else {
			setData([]);
		}
	}, [navigate, viewLevel]);

	useEffect(() => {
		sendHttpRequest('POST', '/cartons', {}, navigate).then((response) => {
			dispatch(setStockItems(response.data[0]));
		});
	}, [dispatch, navigate]);

	const [grouping, setGrouping] = useState('template' as 'template' | 'usage' | 'gp');

	return (
		<div>
			{loading && (
				<div className="row justify-content-center pt-5 mt-5">
					<Loading />
				</div>
			)}
			{!loading && (
				<form className="create-product-form pt-3">
					<div className="template-wrapper create-product-form-header">
						<div className="template-name">&nbsp;</div>
						<div className="d-flex w-100">
							<div className="col-12 ms-3 d-flex justify-content-between">
								<div className="d-flex text-nowrap">
									<div className="btn-group me-3" role="group">
										<button
											className={'btn btn-primary' + (grouping === 'template' ? ' active' : '')}
											type="button"
											onClick={() => setGrouping('template')}>
											Template
										</button>
										<button
											className={'btn btn-primary' + (grouping === 'usage' ? ' active' : '')}
											type="button"
											onClick={() => setGrouping('usage')}>
											Usage
										</button>
										<button
											className={'btn btn-primary' + (grouping === 'gp' ? ' active' : '')}
											type="button"
											onClick={() => setGrouping('gp')}>
											GP category
										</button>
									</div>
									<div className="btn-group me-3" role="group">
										<button
											className={'btn btn-primary' + (viewLevel === 'summary' ? ' active' : '')}
											type="button"
											onClick={() => setViewLevel('summary')}>
											Summary
										</button>
										<button
											className={'btn btn-primary' + (viewLevel === 'products' ? ' active' : '')}
											type="button"
											onClick={() => setViewLevel('products')}>
											Products
										</button>
										<button
											className={'btn btn-primary' + (viewLevel === 'stock_items' ? ' active' : '')}
											type="button"
											onClick={() => setViewLevel('stock_items')}>
											Stock items
										</button>
									</div>
									<div className="btn-group me-3" role="group">
										{allCountries.map((country, country_index) => (
											<button
												key={country_index}
												className={
													'btn btn-primary' + (countries.includes(country) ? ' active' : '')
												}
												type="button"
												onClick={() =>
													setCountries(
														countries.includes(country)
															? countries.filter((c) => c !== country)
															: [...countries, country]
													)
												}>
												{country}
											</button>
										))}
									</div>
								</div>
								<div className="pe-5 d-flex">
									<div>
										<Dropdown>
											<Dropdown.Toggle variant="outline-primary" id="dropdown-layout">
												<span>{currentLayout?.name ?? 'Custom layout'}</span>
												<i className="ms-2 bi-chevron-down"></i>
											</Dropdown.Toggle>

											<Dropdown.Menu>
												<Dropdown.Item
													onClick={(e) => {
														e.stopPropagation();
														saveLayout();
													}}>
													Save current layout
												</Dropdown.Item>

												<hr />

												{layouts.map((layout, i) => (
													<div key={i} className="btn-group d-flex">
														<Dropdown.Item
															className="layout-selector"
															onClick={() => {
																loadLayout(layout);
															}}>
															{layout.name}
														</Dropdown.Item>
														<Dropdown.Item
															className="layout-trash text-end"
															onClick={() => {
																deleteLayout(layout);
															}}>
															<i className="bi bi-trash" />
														</Dropdown.Item>
													</div>
												))}
											</Dropdown.Menu>
										</Dropdown>
									</div>
									<div className="ms-3">
										<Dropdown>
											<Dropdown.Toggle variant="outline-primary" id="dropdown-omit-columns">
												<span>Columns visibility</span>
												<i className="ms-2 bi-chevron-down"></i>
											</Dropdown.Toggle>

											<Dropdown.Menu>
												<Dropdown.Item
													onClick={(e) => {
														e.stopPropagation();
														hideAllColumns();
													}}>
													Hide all columns
												</Dropdown.Item>
												<Dropdown.Item
													onClick={(e) => {
														e.stopPropagation();
														showAllColumns();
													}}>
													Show all columns
												</Dropdown.Item>

												<hr />

												{(structures[0]?.tabs ?? []).map((t, i) => (
													<Fragment key={i}>
														{t.columns.map((col, col_index) => (
															<Fragment key={col_index}>
																<Dropdown.Item
																	onClick={(e) => {
																		e.stopPropagation();
																		toggleColumnVisibility(col.key);
																	}}
																	className={omitColumns.includes(col.key) ? 'color-gray' : ''}>
																	{omitColumns.includes(col.key) ? (
																		<i className="bi-eye-slash me-3" />
																	) : (
																		<i className="bi-eye me-3" />
																	)}
																	{col.name}
																</Dropdown.Item>
															</Fragment>
														))}
													</Fragment>
												))}
											</Dropdown.Menu>
										</Dropdown>
									</div>
								</div>
							</div>
						</div>
					</div>
					{grouping == 'template' && (
						<div className="row">
							{templates.map((template, i) => (
								<PromotionTemplate
									key={i}
									template={template}
									tabs={structures[0].tabs}
									countriesTabs={structures[0].countries ?? []}
									viewLevel={viewLevel}
									products={data.filter((row) => row.i_template === template)}
									index={i}
									addProduct={addProduct}
									i_business={i_business}
									countries={countries}
									omitColumns={omitColumns}
									setOmitColumns={changeOmittedColumns}
								/>
							))}
						</div>
					)}
					{grouping == 'usage' && (
						<div className="row">
							{usages.map((usg, i) => (
								<PromotionTemplate
									key={i}
									template={usg}
									tabs={structures[0].tabs}
									countriesTabs={structures[0].countries}
									viewLevel={viewLevel}
									products={data.filter((row) => row.i_usg === usg)}
									index={i}
									addProduct={addProduct}
									i_business={i_business}
									countries={countries}
									omitColumns={omitColumns}
									setOmitColumns={changeOmittedColumns}
								/>
							))}
						</div>
					)}
					{grouping == 'gp' && (
						<div className="row">
							{gpCategories.map((gpc, i) => (
								<PromotionTemplate
									key={i}
									template={gpc}
									tabs={structures[0].tabs}
									countriesTabs={structures[0].countries}
									viewLevel={viewLevel}
									products={data.filter((row) => row.GP_group === gpc)}
									index={i}
									addProduct={addProduct}
									i_business={i_business}
									countries={countries}
									omitColumns={omitColumns}
									setOmitColumns={changeOmittedColumns}
								/>
							))}
						</div>
					)}
				</form>
			)}
		</div>
	);
}
