import React from 'react';
import { MenuItem } from 'utils/menu';
import MenuListItem from 'components/menu/MenuListItem';

export default function MenuItemChild(props: {
	item: MenuItem;
	openedChildPosition: string;
	setOpenedChildPosition: (pos: string) => void;
	parentPosition: string;
	setParentPosition: (pos: string) => void;
	setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
	return (
		<div className="menu-item-child">
			<div className="d-flex align-items-start pe-2">
				<a
					role="button"
					className="ps-2 pe-1 color-gray-shade"
					onClick={() => props.setOpenedChildPosition(props.parentPosition)}>
					<i className="bi-arrow-left-short fs-3" />
				</a>
				<div className="ms-1 d-flex align-items-center flex-wrap">
					<span className="px-1 name color-gray-shade">{props.item.menu_item_name}</span>
				</div>
			</div>
			<div>
				<ul>
					{props.item.children &&
						props.item.children.map((child) => (
							<MenuListItem
								key={child.position}
								item={child}
								ancestor={props.item.position}
								setOpenedChildPosition={props.setOpenedChildPosition}
								setParentPosition={props.setParentPosition}
								setIsOpen={props.setIsOpen}
							/>
						))}
				</ul>
			</div>
		</div>
	);
}
