import { ResponseMessage, useSendData } from 'sockets/sockets';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { getAuthItem } from 'utils/httpRequestManager';
import { setResponse } from 'state/socketSlice';

const useHandleResponse = () => {
	const dispatch = useDispatch();
	return useCallback(
		(m: ResponseMessage) => {
			if (!m) return;
			dispatch(setResponse(m));
		},
		[dispatch]
	);
};

export const useGetDefaultNamespace = () => {
	const handler = useHandleResponse();
	return useCallback(
		(m: ResponseMessage) => {
			handler(m);
		},
		[handler]
	);
};

export const useSendRequest = () => {
	const send = useSendData();
	const token = getAuthItem()?.token ?? '';

	return useCallback(
		(request: string, params: object) => {
			send({
				token: token,
				namespace: 'default',
				request: request,
				params: params
			});
		},
		[send, token]
	);
};
