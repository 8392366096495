export function capitalizeFirstLetter(s: string): string {
	return s.charAt(0).toUpperCase() + s.slice(1);
}

export function humanizeString(s: string): string {
	return capitalizeFirstLetter(s.replace(/_/g, ' ').trim());
}

export function trimAtSign(s: string): string {
	return s.replace('@', '');
}
