import React, { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import Input from 'components/Forms/FormComponents/Input';
import ProgressModal from 'components/parts/ProgressModal';
import { useNavigate } from 'react-router';
import { Status } from 'sockets/sockets';
import { sendHttpRequest } from 'utils/httpRequestManager';

export default function ProductImport() {
	const [showModal, setShowModal] = useState(false);
	const [status, setStatus] = useState('' as Status);
	type Inputs = {
		file: FileList;
	};

	const navigate = useNavigate();

	const {
		register,
		handleSubmit,
		formState: { errors }
	} = useForm<Inputs>();
	const onSubmit: SubmitHandler<Inputs> = (data) => {
		setStatus(Status.PENDING);
		setShowModal(true);

		sendHttpRequest(
			'POST',
			process.env.REACT_APP_NODE_API + 'product/import',
			{ file: data.file[0] },
			navigate
		)
			.then(() => {
				setStatus(Status.DONE);
			})
			.catch(() => {
				setStatus(Status.ERROR);
			});
	};

	const closeModal = () => {
		setShowModal(false);
		navigate('/');
	};

	return (
		<>
			<div className="row justify-content-center">
				<div className="col-5">
					<h1 className="mb-4 mt-3">Product import</h1>
					<form onSubmit={handleSubmit(onSubmit)} noValidate>
						<Input
							name="file"
							type="file"
							label="Product definition file"
							required={true}
							register={register}
							error={errors.file}
						/>
						<button type="submit" className="btn btn-primary w-100">
							Submit
						</button>
					</form>
				</div>
			</div>
			<ProgressModal status={status} showModal={showModal} closeModal={closeModal} />
		</>
	);
}
