import { configureStore } from '@reduxjs/toolkit';
import userReducer from 'state/userSlice';
import menuReducer from 'state/menuSlice';
import componentReducer from 'state/componentSlice';
import socketReducer from 'state/socketSlice';
import stockItemReducer from 'state/stockItemSlice';

export const store = configureStore({
	reducer: {
		user: userReducer,
		menu: menuReducer,
		components: componentReducer,
		socket: socketReducer,
		stockItem: stockItemReducer
	}
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
