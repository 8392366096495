import axios, { AxiosError } from 'axios';
import { LoginResponse } from 'utils/auth';
import { NavigateFunction } from 'react-router-dom';
import { authItemKey, unsetUser } from 'state/userSlice';
import { UnknownAction } from '@reduxjs/toolkit';
import { Dispatch } from 'react';

axios.defaults.baseURL = process.env.REACT_APP_NODE_API;
axios.defaults.headers.post['Content-Type'] = 'application/json';

export function getAuthItem(): LoginResponse | null {
	const item = localStorage.getItem(authItemKey);
	return item ? (JSON.parse(item) as LoginResponse) : null;
}

export function isUserLoggedIn(): boolean {
	return !!getAuthItem();
}

export function setAuthItem(token: LoginResponse) {
	localStorage.setItem(authItemKey, JSON.stringify(token));
}

function clearLocalStorage() {
	localStorage.clear();
}

export function logOut(dispatch: Dispatch<UnknownAction>) {
	clearLocalStorage();
	dispatch(unsetUser());
}

function refreshAuthToken(navigate: NavigateFunction): Promise<void> {
	const authItem = getAuthItem();

	if (authItem) {
		if (
			new Date(authItem.expires_at) < new Date() &&
			new Date(authItem.rt_expires_at) > new Date()
		) {
			const headers = { Authorization: `Bearer ${authItem.token}` };

			return axios({
				headers,
				method: 'POST',
				url: '/refresh-token',
				data: { refresh_token: authItem.refresh_token }
			})
				.then((response) => {
					setAuthItem(response.data as LoginResponse);
				})
				.catch((error) => {
					if (error instanceof AxiosError) {
						navigate('/login');
					}
				});
		}
	}
	return new Promise((resolve) => resolve());
}

export function sendHttpRequest(
	method: string,
	url: string,
	data: object | null,
	navigate: NavigateFunction
) {
	return refreshAuthToken(navigate).then(() => {
		const authItem = getAuthItem();

		const headers: { Authorization?: string; 'Content-Type'?: string } = authItem
			? { Authorization: `Bearer ${authItem.token}` }
			: {};

		if (data && Object.values(data).some((value) => value instanceof File)) {
			headers['Content-Type'] = 'multipart/form-data';
		}

		return axios({
			headers: headers,
			method: method,
			url: url,
			data: data
		});
	});
}

export function executeAction(data: object, navigate: NavigateFunction) {
	return sendHttpRequest('POST', '/execute', data, navigate);
}
