import React, { useEffect, useState } from 'react';
import { ReactComponent as Logo } from 'assets/svgs/tupperware_logo.svg';
import { Link, useNavigate } from 'react-router-dom';
import Menu from 'components/menu/Menu';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'state/store';
import { getMenuItems } from 'utils/menu';
import { isUserLoggedIn } from 'utils/httpRequestManager';
import { setMenu } from 'state/menuSlice';

export default function Header() {
	const user = useSelector((state: RootState) => state.user.user);
	const menu = useSelector((state: RootState) => state.menu.menu);

	const [openedItemPosition, setOpenedItemPosition] = useState('');

	const navigate = useNavigate();
	const dispatch = useDispatch();

	useEffect(() => {
		if (isUserLoggedIn() && window.location.pathname != '/logout') {
			getMenuItems(navigate).then((r) => {
				if (r.children) {
					dispatch(setMenu(r.children));
				}
			});
		} else if (window.location.pathname == '/logout') {
			setOpenedItemPosition('');
			dispatch(setMenu([]));
		}
	}, [dispatch, navigate]);

	const location = menu.find((item) => item.position === '000')?.location;

	return (
		<header className="header d-flex justify-content-between align-items-center">
			<div className="d-flex align-items-center">
				<Link className="logo-link cursor-pointer" to={'/'}>
					<Logo className="w-100" />
					<span className="position-absolute">{location}</span>
				</Link>
				<div className="ps-5 d-flex align-items-center">
					{menu
						.filter((item) => item.position !== '000')
						.map((item) => (
							<Menu
								key={item.position}
								parentItem={item}
								openedItemPosition={openedItemPosition}
								setOpenedItemPosition={setOpenedItemPosition}
							/>
						))}
				</div>
			</div>
			<div>
				{!user && <Link to={'/login'}>Sign in</Link>}
				{user &&
					menu
						.filter((item) => item.position === '000')
						.map((item) => (
							<Menu
								key={item.position}
								parentItem={item}
								openedItemPosition={openedItemPosition}
								setOpenedItemPosition={setOpenedItemPosition}
							/>
						))}
			</div>
		</header>
	);
}
