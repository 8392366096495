import React from 'react';
import { AppComponent, ComponentDefinition } from 'utils/components';
import DynamicPage from 'pages/DynamicPage';
import { humanizeString } from 'utils/string';

type TabParameters = {
	tab_name: string;
	i_page: string;
};

export default function Tab(props: {
	component: AppComponent;
	definition: ComponentDefinition | undefined;
}) {
	const variables = JSON.parse(props.component.variables);

	const parameters = JSON.parse(props.definition?.component_parameters ?? '{}');

	const tabs =
		typeof parameters.tabs == 'object'
			? (parameters.tabs as TabParameters[])
			: (JSON.parse(variables.tab_parameters) as TabParameters[]);

	const [activeTab, setActiveTab] = React.useState(tabs[0].tab_name);

	return (
		<>
			<ul className="nav nav-underline">
				{tabs.map((t, i) => (
					<li className="nav-item" key={i}>
						<button
							onClick={() => setActiveTab(t.tab_name)}
							className={'nav-link' + (t.tab_name == activeTab ? ' active' : '')}
							aria-current={t.tab_name == activeTab ? 'page' : undefined}>
							{humanizeString(t.tab_name)}
						</button>
					</li>
				))}
			</ul>
			<div>
				{tabs.map((t, i) => (
					<div
						key={i}
						className="pt-3"
						style={{ display: t.tab_name == activeTab ? 'block' : 'none' }}>
						<DynamicPage i_page={t.i_page} />
					</div>
				))}
			</div>
		</>
	);
}
