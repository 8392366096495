import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ResponseMessage } from 'sockets/sockets';

export interface SocketState {
	open: boolean;
	responses: ResponseMessage[];
}

const initialState: SocketState = {
	open: false,
	responses: []
};

const socketSlice = createSlice({
	name: 'socket',
	initialState,
	reducers: {
		setOpen(state, action: PayloadAction<boolean>) {
			state.open = action.payload;
		},
		setResponse(state, action: PayloadAction<ResponseMessage>) {
			const responses = state.responses.filter((r) => r.request !== action.payload.request);
			responses.push(action.payload);
			state.responses = responses;
		},
		clearResponse(state, action: PayloadAction<string>) {
			state.responses = state.responses.filter((r) => r.request !== action.payload);
		}
	}
});

export default socketSlice.reducer;
export const { setOpen, setResponse, clearResponse } = socketSlice.actions;
