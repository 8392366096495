import React from 'react';
import { AppComponent } from 'utils/components';
import { useSelector } from 'react-redux';
import { RootState } from 'state/store';
import DynamicComponent from 'components/DynamicComponent';

export default function Row(props: { component: AppComponent }) {
	const componentDefinitions = useSelector((state: RootState) => state.components.components);

	if (!props.component.children) return <></>;

	return (
		<div className="row">
			{props.component.children.map((component, i) => (
				<DynamicComponent
					key={i}
					component={component}
					has_definitions={componentDefinitions.length > 0}
					definition={componentDefinitions.find((c) => c.i_component == component.i_component)}
				/>
			))}
		</div>
	);
}
