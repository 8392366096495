import { useMemo } from 'react';
import { useGetDefaultNamespace } from 'sockets/default';

export const useNamespacesMapping = () => {
	const def = useGetDefaultNamespace();
	return useMemo(
		() => ({
			default: def
		}),
		[def]
	);
};
