import React, { Fragment } from 'react';
import Input from 'components/Forms/FormComponents/Input';
import { Product, Tab } from 'components/Forms/ProductsForm';

export default function PromotionTemplateSummary(props: {
	data: Product[];
	template: string;
	srpTemplateTotal: number;
	basePriceTemplateTotal: number;
	retailPriceTemplateTotal: number;
	showTemplate: boolean;
	getValue: (tab_key: string, col_key: string, productIndex: number, c?: string) => string;
	tabs: Tab[];
	countries: string[];
	omitColumns: string[];
	setOmitColumns: (omitColumns: string[]) => void;
}) {
	function calculateColumnSum(tab_key: string, col_key: string) {
		return props.countries
			.map((c) =>
				props.data.reduce(
					(sum, p, i) => parseFloat(props.getValue(tab_key, col_key, i, c) ?? '0') + sum,
					0
				)
			)
			.reduce((sum, c) => sum + c, 0);
	}

	return (
		<div className="row mt-3 mx-0 promotion-template-summary">
			<div className="promotion-sticky-left summary-section pe-2 pb-2">
				<div className="d-flex p-3 align-items-center">
					<div className="col-5 summary-template-name">
						{props.showTemplate && <h2>{props.template}</h2>}
					</div>
					<div className="col-3 px-1">
						<Input
							name={props.template + '.retail_price_template_total'}
							label={'Retail price'}
							type="number"
							customClass="text-end"
							min={0}
							step={0.01}
							required={true}
							disabled={true}
							defaultValue={props.retailPriceTemplateTotal.toFixed(2)}
						/>
					</div>

					<div className="col-2 px-1">
						<Input
							name={props.template + '.srp_template_total'}
							label={'SRP'}
							type="number"
							customClass="text-end"
							min={0}
							disabled={true}
							step={0.01}
							defaultValue={props.srpTemplateTotal.toFixed(2)}
							required={true}
						/>
					</div>

					<div className="col-2 px-1">
						<Input
							name={props.template + '.base_price_template_total'}
							label={'Base price'}
							type="number"
							customClass="text-end"
							min={0}
							disabled={true}
							step={0.01}
							defaultValue={props.basePriceTemplateTotal.toFixed(2)}
							required={true}
						/>
					</div>
				</div>
			</div>
			<div className="summary-section ps-2">
				<div className="d-flex p-3 align-items-center">
					<div className="d-flex">
						{props.tabs.map((t, i) => (
							<Fragment key={i}>
								{t.columns.map((col, col_index) => (
									<Fragment key={col_index}>
										{!props.omitColumns.includes(col.key) && (
											<div className="position-relative">
												<div
													className={
														(col.type == 'datetime-local' ? 'col px-1 datetime' : 'col px-1') +
														(col.type !== 'number' ? ' opacity-0' : '')
													}>
													<Input
														label={col.name}
														name={props.template + '.sum.' + i + '.' + col.key}
														type={col.type}
														customClass={col.type == 'number' ? 'text-end' : ''}
														step={col.type == 'number' ? 0.01 : undefined}
														defaultValue={
															col.type === 'number'
																? calculateColumnSum(t.key, col.key).toFixed(2)
																: ''
														}
														required={false}
														disabled={true}
													/>
												</div>
												<button
													className="btn hide-column-btn"
													type="button"
													onClick={() => props.setOmitColumns([...props.omitColumns, col.key])}>
													<i className="bi-eye-slash" />
												</button>
											</div>
										)}
									</Fragment>
								))}
							</Fragment>
						))}
					</div>
				</div>
			</div>
		</div>
	);
}
