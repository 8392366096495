import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { StockItemOption } from 'components/Forms/Promotion/PromotionProduct';

export interface StockItemState {
	stockItems: StockItemOption[] | null;
}

const initialState: StockItemState = {
	stockItems: []
};

const userSlice = createSlice({
	name: 'stockItem',
	initialState,
	reducers: {
		setStockItems(state, action: PayloadAction<StockItemOption[]>) {
			state.stockItems = action.payload;
		}
	}
});

export default userSlice.reducer;
export const { setStockItems } = userSlice.actions;
