import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppComponent, getPageComponentTree } from 'utils/components';
import DynamicComponent from 'components/DynamicComponent';
import Loading from 'components/parts/Loading';
import { useSelector } from 'react-redux';
import { RootState } from 'state/store';

export default function DynamicPage(props: { i_page?: string }) {
	const navigate = useNavigate();

	const [components, setComponents] = useState([] as AppComponent[]);
	const [loading, setLoading] = useState(false);

	const componentDefinitions = useSelector((state: RootState) => state.components.components);

	useEffect(() => {
		setLoading(true);
		const path = props.i_page ?? window.location.pathname;
		getPageComponentTree(path, navigate)
			.then((components) => {
				setComponents(components);
				setLoading(false);
			})
			.finally(() => setLoading(false));
	}, [navigate, props.i_page]);

	return (
		<>
			{loading && <Loading />}
			{!loading &&
				components.map((component, i) => (
					<DynamicComponent
						key={i}
						component={component}
						has_definitions={componentDefinitions.length > 0}
						definition={componentDefinitions.find((c) => c.i_component == component.i_component)}
					/>
				))}
		</>
	);
}
