import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LoginResponse, User } from 'utils/auth';

export const authItemKey = 'authItem';

export interface UserState {
	user: User | null;
}

const initialState: UserState = {
	user: localStorage.getItem(authItemKey)
		? (JSON.parse(localStorage.getItem(authItemKey) as string) as LoginResponse).user
		: null
};

const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		setUser(state, action: PayloadAction<User>) {
			state.user = action.payload;
		},
		unsetUser(state) {
			state.user = null;
		}
	}
});

export default userSlice.reducer;
export const { setUser, unsetUser } = userSlice.actions;
