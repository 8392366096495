import React, { useState } from 'react';
import { FieldError, FieldValues, Path, UseFormGetValues, UseFormRegister } from 'react-hook-form';
import { ValidationRule } from 'react-hook-form/dist/types/validator';

export default function PasswordInput<T extends FieldValues>(props: {
	inputId?: string;
	name?: string;
	customClass?: string;
	label?: string;
	register?: UseFormRegister<T>;
	error?: FieldError;
	defaultValue?: string;
	required: boolean;
	minLength?: number;
	pattern?: ValidationRule<RegExp>;
	patternDescription?: string;
	autoComplete?: string;
	match?: string;
	getValues?: UseFormGetValues<T>;
	onChange?: () => void;
	customError?: string;
}) {
	const [showPassword, setShowPassword] = useState(false);
	const inputId = props.inputId ?? props.name;

	if (props.register === undefined) {
		return <></>;
	}

	return (
		<>
			<div
				className={
					'position-relative form-floating password-input ' +
					(props.customClass ? props.customClass : '')
				}>
				<input
					{...props.register(props.name as Path<T>, {
						required: props.required,
						minLength: props.minLength,
						pattern: props.pattern
					})}
					required={props.required}
					type={showPassword ? 'text' : 'password'}
					id={inputId}
					defaultValue={props.defaultValue}
					className={'form-control' + (props.error || props.customError ? ' is-invalid' : '')}
					autoComplete={props.autoComplete ?? 'on'}
					onInput={props.onChange}
				/>
				<label htmlFor={inputId}>{props.label}</label>
				{!props.error && !props.customError && (
					<div className="invalid-feedback d-block">&nbsp;</div>
				)}
				{props.error && props.error.type == 'required' && (
					<div className="invalid-feedback">{props.label} is required.</div>
				)}
				{props.error && props.error.type == 'minLength' && (
					<div className="invalid-feedback">
						{props.label} must be at least {props.minLength} characters long.
					</div>
				)}
				{props.error && props.error.type == 'pattern' && (
					<div className="invalid-feedback">
						{props.label} {props.patternDescription}.
					</div>
				)}
				{props.customError && <div className="invalid-feedback">{props.customError}</div>}
				<button
					type="button"
					className="show-password-button"
					onClick={() => setShowPassword(!showPassword)}>
					{showPassword && <i className="bi bi-eye"></i>}
					{!showPassword && <i className="bi bi-eye-slash"></i>}
				</button>
			</div>
		</>
	);
}
