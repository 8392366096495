import React, { useRef, useState, useEffect } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FieldError, FieldValues, Path, UseFormRegister } from 'react-hook-form';
import { ValidationRule } from 'react-hook-form/dist/types/validator';

export default function Input<T extends FieldValues>(props: {
	inputId?: string;
	type: string;
	name?: string;
	customClass?: string;
	label?: string;
	register?: UseFormRegister<T>;
	error?: FieldError;
	defaultValue?: string;
	required: boolean;
	step?: number;
	min?: number;
	max?: number;
	minLength?: number;
	maxLength?: number;
	pattern?: ValidationRule<RegExp>;
	patternDescription?: string;
	disabled?: boolean;
	onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
	autoComplete?: string;
	customError?: string;
}) {
	const inputId = props.inputId ?? props.name;

	const labelRef = useRef<HTMLLabelElement>(null);
	const [labelOverflowing, setLabelOverflowing] = useState(false);

	useEffect(() => {
		const label = labelRef.current;
		if (label) {
			const isOverflow = label.scrollWidth > label.clientWidth;
			setLabelOverflowing(isOverflow);
		}
	}, []);

	if (props.register === undefined) {
		return (
			<>
				<div className="form-floating">
					<input
						disabled={props.disabled}
						type={props.type}
						step={props.step}
						id={inputId}
						value={props.defaultValue}
						maxLength={props.maxLength}
						className={
							'form-control ' +
							(props.customClass ?? '') +
							(props.error || props.customError ? ' is-invalid' : '')
						}
						onInput={props.onChange}
						autoComplete={props.autoComplete}
					/>
					<label ref={labelRef} htmlFor={inputId}>
						{props.label}
					</label>
					{labelOverflowing && (
						<OverlayTrigger overlay={<Tooltip id={inputId + '-tooltip'}>{props.label}</Tooltip>}>
							<a href="#" className="tooltip-trigger" />
						</OverlayTrigger>
					)}
				</div>
			</>
		);
	}

	return (
		<>
			<div className="form-floating">
				<input
					{...props.register(props.name as Path<T>, {
						required: props.required,
						min: props.min,
						max: props.max,
						minLength: props.minLength,
						maxLength: props.maxLength,
						pattern: props.pattern
					})}
					disabled={props.disabled}
					type={props.type}
					step={props.step}
					id={inputId}
					defaultValue={props.defaultValue}
					maxLength={props.maxLength}
					className={
						'form-control ' +
						(props.customClass ?? '') +
						(props.error || props.customError ? ' is-invalid' : '')
					}
					onInput={props.onChange}
					autoComplete={props.autoComplete}
				/>
				<label htmlFor={inputId}>{props.label}</label>
				{!props.error && !props.customError && (
					<div className="invalid-feedback error-placeholder d-block">&nbsp;</div>
				)}
				{props.error && props.error.type == 'required' && (
					<div className="invalid-feedback">{props.label} is required.</div>
				)}
				{props.error && props.error.type == 'min' && (
					<div className="invalid-feedback">
						{props.label} must be at least {props.min}.
					</div>
				)}
				{props.error && props.error.type == 'max' && (
					<div className="invalid-feedback">
						{props.label} must be at most {props.max}.
					</div>
				)}
				{props.error && props.error.type == 'minLength' && (
					<div className="invalid-feedback">
						{props.label} must be at least {props.minLength} characters long.
					</div>
				)}
				{props.error && props.error.type == 'maxLength' && (
					<div className="invalid-feedback">
						{props.label} must be at most {props.maxLength} characters long.
					</div>
				)}
				{props.customError && <div className="invalid-feedback">{props.customError}</div>}
				{props.error && props.error.type == 'pattern' && (
					<div className="invalid-feedback">
						{props.label} {props.patternDescription}.
					</div>
				)}
			</div>
		</>
	);
}
