import React from 'react';
import { Status } from 'sockets/sockets';
import Loading from 'components/parts/Loading';
import { Link } from 'react-router-dom';

export default function ProgressModal(props: {
	status: string | null;
	showModal: boolean;
	closeModal: () => void;
}) {
	return (
		<div className={'modal-custom-wrapper' + (props.showModal ? ' show' : '')}>
			<div className="modal-custom p-5">
				{props.status !== Status.DONE && props.status !== Status.ERROR && (
					<div className="pb-4">
						<Loading />
					</div>
				)}
				{props.status === Status.DONE && (
					<>
						<i className="bi bi-check2-circle color-green"></i>
						<div className="text-center">Successfully completed.</div>
					</>
				)}
				{props.status === Status.ERROR && (
					<>
						<i className="bi bi-exclamation-diamond color-red"></i>
						<div className="text-center">
							The request finished with an unexpected error.
							<br />
							Please try again later.
						</div>
					</>
				)}
				{props.status !== Status.DONE && props.status !== Status.ERROR && (
					<>
						<div className="text-center">{props.status ?? 'Please wait...'}</div>
					</>
				)}
				{props.status === Status.DONE && (
					<Link className="btn btn-primary mt-4" to={'/'}>
						Back to Homepage
					</Link>
				)}
				{props.status === Status.ERROR && (
					<button
						type="button"
						className="btn btn-primary mt-4 px-5"
						onClick={() => props.closeModal()}>
						Back
					</button>
				)}
			</div>
		</div>
	);
}
