import React, { useState, useEffect } from 'react';
import NonFloatingInput from 'components/Forms/FormComponents/NonFloatingInput';

export default function PromotionCell(props: {
	template: string;
	productIndex: number;
	productId: number;
	country?: string;
	countryIndex?: number;
	name: string;
	type: string;
	required: boolean;
	itemId?: number;
	tabName?: string;
	customClass?: string;
	min?: number;
	defaultValue?: string;
	getValue?: (tab_key: string | null, col_key: string, productIndex: number, c?: string) => string;
	setValue: (
		item_id: number | null,
		tab_name: string | null,
		input_name: string,
		value: string,
		country_index?: number
	) => void;
	step?: number;
	disabled?: boolean;
	onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}) {
	useEffect(() => {
		if (props.defaultValue !== undefined) {
			setValue(props.defaultValue);
		} else if (props.getValue !== undefined) {
			setValue(
				props.getValue(props.tabName ?? null, props.name, props.productIndex, props.country)
			);
		}
	}, [props.defaultValue]);

	const [value, setValue] = useState('');

	return (
		<NonFloatingInput
			name={
				props.template +
				'.' +
				props.productIndex +
				'.' +
				(props.country ?? '') +
				'.' +
				(props.itemId ?? '') +
				'.' +
				props.name
			}
			type={props.type}
			defaultValue={value}
			customClass={props.customClass ?? ''}
			onChange={(e) => {
				setValue(e.target.value);
				props.onChange?.(e);
				props.setValue(
					props.itemId ?? null,
					props.tabName ?? null,
					props.name,
					e.target.value,
					props.countryIndex
				);
			}}
			hideErrorPlaceholder={true}
			required={props.required}
			step={props.step}
			min={props.min}
			disabled={props.disabled}
		/>
	);
}
