import React, { useState } from 'react';
import Input from 'components/Forms/FormComponents/Input';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { sendHttpRequest } from 'utils/httpRequestManager';
import { MessageResponse } from 'utils/auth';
import { AxiosError } from 'axios';
import Alert from 'components/parts/Alert';
import Loading from 'components/parts/Loading';

export default function ForgotPassword() {
	const [error, setError] = useState({ type: '', message: '' });
	const [confirmation, setConfirmation] = useState('');
	const [loading, setLoading] = useState(false);

	const navigate = useNavigate();

	type Inputs = {
		email: string;
	};

	const {
		register,
		handleSubmit,
		formState: { errors }
	} = useForm<Inputs>();

	const removeError = () => {
		setError({ type: '', message: '' });
	};

	const onSubmit: SubmitHandler<Inputs> = (data) => {
		const requestData = {
			email: data.email,
			project_url: window.location.origin
		};
		setLoading(true);
		sendHttpRequest('POST', '/forgot-password', requestData, navigate)
			.then((response) => {
				const r = response.data as MessageResponse;
				setConfirmation(r.message);
			})
			.catch((e) => {
				if (e instanceof AxiosError) {
					setError({ type: 'error', message: e.response?.data.message });
				}
			})
			.finally(() => setLoading(false));
	};

	return (
		<>
			<div className="row justify-content-center">
				<div className="col-12 col-sm-8 col-lg-4 py-5">
					<h1 className="text-center mb-5">Forgotten password</h1>
					{confirmation !== '' && <Alert type="success" message={confirmation} />}
					{loading && <Loading />}
					{confirmation === '' && !loading && (
						<>
							<div className="mb-3 small">
								Please enter your email. We will send you the link to reset your password.
							</div>
							<form onSubmit={handleSubmit(onSubmit)} noValidate>
								{error.type !== 'custom' && error.message !== '' && (
									<Alert type="danger" message={error.message} />
								)}

								<Input<Inputs>
									name="email"
									label="Email address"
									type="email"
									required={true}
									register={register}
									error={errors.email}
									onChange={removeError}
								/>

								<button type="submit" className="btn btn-primary w-100 mt-1">
									Send
								</button>
							</form>
						</>
					)}
				</div>
			</div>
		</>
	);
}
